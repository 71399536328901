<template>
  <div>
    <ul class="tab">
      <li class="tile tile--tab tab__item active">
        <router-link :to="{ name: 'CrowdfundingUsers' }" tag="p" exact class="tile__content text-uppercase"
          >Joined Users</router-link
        >
      </li>
      <li class="tile tile--tab tab__item">
        <router-link :to="{ name: 'CrowdfundingFeeds' }" tag="p" exact class="tile__content text-uppercase"
          >Can Join</router-link
        >
      </li>
    </ul>

    <div class="box box--sm box--gray radius-bottom-0 mt-16 d-flex">
      <div class="d-flex">
        <selection-box
          v-model="params.plan"
          :options="crowdfundingPlanOptions"
          :on-check="onGetCrowdfundingUsers"
          selected-box-class="is-md"
        />

        <selection-box
          v-model="params.status"
          :options="stakingUsersStatusOptions"
          :is-align-right="true"
          :on-check="onGetCrowdfundingUsers"
          selected-box-class="w-174"
          class="mr-8"
        />

        <date-range-picker
          v-model="dateContext"
          opens="left"
          format="yyyy-mm-dd hh:mm"
          @toggle="isDateRangeOpen = !isDateRangeOpen"
          @update="onGetCrowdfundingUsers"
          :class="['mr-16 bg-gray', { active: isDateRangeOpen }]"
          :append-to-body="true"
          :date-format="dateFormat"
        />
      </div>

      <selection-box
        v-model="params.perPage"
        :options="pagerOptions"
        :is-align-right="true"
        :on-check="onGetCrowdfundingUsers"
      />
    </div>

    <div
      v-if="joinedUsers && joinedUsers.data && joinedUsers.data.length"
      class="table-wrapper table-wrapper--fluid table-wrapper--expand radius-top-0"
    >
      <table class="table table--striped">
        <thead>
          <tr>
            <th>Acc. ID</th>
            <th>Email</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Deposited</th>
            <th>Interest Rate</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          <router-link
            v-for="order in joinedUsers.data"
            :key="order.id"
            :to="{ name: 'CrowdfundingUser', params: { id: order.id } }"
            tag="tr"
            class="link--clone"
          >
            <td :class="{ 'badge badge--triangle-shape tooltip': order.user.blacklist > 0 }">
              {{ order.user.id }}
              <span class="tooltip__text" v-if="order.user.blacklist > 0">{{
                order.user.blacklist | format_blacklist_type
              }}</span>
            </td>
            <td>{{ order.user.email }}</td>
            <td>{{ order.joinedDate | moment_l }}</td>
            <td>{{ order.endDate | moment_l }}</td>
            <td>{{ order.depositedAmount | number_with_commas }} {{ order.token.symbol }}</td>
            <td>{{ order.crowdfundingInterestRate.title }}</td>
            <td class="w-128" v-html="formatStakingStatus(order.status)"></td>
          </router-link>
        </tbody>
      </table>

      <div class="text-center mt-32">
        <p>{{ joinedUsers.total | format_user_count_text }} in total</p>

        <pagination
          v-if="isNextPage(joinedUsers.total)"
          v-model="page"
          :page-count="getPageCount(joinedUsers.totalPages, joinedUsers.total)"
          :page-range="5"
          :click-handler="clickPagerCallback"
        />
      </div>
    </div>

    <no-data v-else title="No stake user available" />

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { PAGER_OPTIONS, STAKING_USERS_STATUS_OPTIONS } from '@/utils/data-sets'
import DateRangePicker from 'vue2-daterange-picker'
import stakingStatus from '@/mixins/staking-status'
import pagination from '@/mixins/pagination'
import dateFormat from '@/mixins/date-range-format'
import unixTime from '@/mixins/unix-time'
import handleAssets from '@/mixins/handle-assets'
import toggleLoading from '@/mixins/toggle-loading'
const NoData = () => import('@/components/NoData')
const Pagination = () => import('@/components/Pagination')
const SelectionBox = () => import('@/components/SelectionBox')

export default {
  data() {
    return {
      params: {
        plan: 0,
        perPage: 20,
        status: 0
      },
      page: 1,
      dateContext: {
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date()
      },
      pagerOptions: PAGER_OPTIONS,
      stakingUsersStatusOptions: STAKING_USERS_STATUS_OPTIONS,
      isDateRangeOpen: false
    }
  },

  mixins: [stakingStatus, pagination, dateFormat, unixTime, handleAssets, toggleLoading],

  computed: {
    ...mapGetters('crowdfunding', ['crowdfundingPlanOptions']),

    ...mapState('crowdfunding', ['joinedUsers', 'assets'])
  },

  components: {
    NoData,
    Pagination,
    SelectionBox,
    DateRangePicker
  },

  methods: {
    ...mapActions('crowdfunding', ['getCrowdfundingPlans', 'getCrowdfundingUsers']),

    ...mapActions('program', ['getProgramList']),

    onGetCrowdfundingUsers() {
      this.toggleLoading()
      this.page = 1
      this.getCrowdfundingUsers(this.getCrowdfundingUsersParams()).finally(() => {
        this.toggleLoading()
      })
    },

    getCrowdfundingUsersParams(params = this.params) {
      const { fromDate, toDate } = this.getDateRangeByUnixTime(this.dateContext)

      return {
        interest_rate_id: params.plan,
        page: this.page,
        limit: params.perPage,
        status: params.status,
        startTime: fromDate,
        endTime: toDate
      }
    },

    clickPagerCallback() {
      this.toggleLoading()
      this.getCrowdfundingUsers(this.getCrowdfundingUsersParams()).finally(() => {
        this.toggleLoading()
      })
    }
  },

  async created() {
    try {
      await this.getProgramList()
      await this.getCrowdfundingPlans()
      await this.getCrowdfundingUsers(this.getCrowdfundingUsersParams())
    } catch (e) {
      this.toggleLoading()
      throw e
    }

    this.toggleLoading()
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'CrowdfundingUser') {
      this.storeAssets('crowdfunding')
      next()
    } else {
      this.clearAssets('crowdfunding')
      next()
    }
  }
}
</script>
